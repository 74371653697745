<template>
    <div class="card max-w-sm ">
        <div class="card-header bg-red-500 px-4 font-bold py-3 text-white-text"  v-text="title"/>
        <div class="card-body p-5 bg-base-300 max-h-80 overflow-y-auto scroll-bar ">
            <p class="text-gray-700 mb-2  text-lg" v-text="note" />
        </div>
    </div>
  </template>
  
  <script>
  export default {
      props: {
          note: {
              type: String,
              default: () => "Lorem ipsum dolar sit amet"
          },
          title: {
              type: String,
              default: () => "Decline Note"
          },
  
      }
  }
  </script>
  
  <style lang="scss" scoped>
  .card {
      // max-width: 30rem;
      min-width: 20rem;
  }
  
  </style>